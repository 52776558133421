var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center"},[_c('p',[_vm._v("Domicilios")]),_c('div',{staticClass:"bg-gr-red cr-pointer br-10 px-3 py-1 text-white",on:{"click":_vm.updateDatos}},[_vm._v(" Guardar ")])]),_c('div',{staticClass:"p-3 f-15 custom-scroll alto-content"},[_c('ValidationObserver',{ref:"validacion_cobertura"},[_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("Cobertura de tu tienda (metros)")]),_c('ValidationProvider',{attrs:{"rules":"required|max:5","name":"cobertura"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-miles',{attrs:{"money-options":_vm.config_distancias},model:{value:(_vm.datos.cobertura),callback:function ($$v) {_vm.$set(_vm.datos, "cobertura", $$v)},expression:"datos.cobertura"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-6"},[_c('label',[_vm._v("Indique el valor mínimo del pedido para que el domicilio sea gratis")]),_c('ValidationProvider',{attrs:{"rules":"required|max:5","name":"valor mínimo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-miles',{model:{value:(_vm.datos.domicilio_gratis),callback:function ($$v) {_vm.$set(_vm.datos, "domicilio_gratis", $$v)},expression:"datos.domicilio_gratis"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('p',{staticClass:"text-general f-12"},[_vm._v("En su tienda se visualizará con domicilios gratis")])]}}])})],1)])]),_c('ValidationObserver',{ref:"validacion_domicilio",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('p',{staticClass:"m-3"},[_vm._v("Crea los rangos del Domicio y su valor")]),_c('div',{staticClass:"row mx-0 my-3"},[_c('div',{staticClass:"col-auto"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"rules":"required|max:5","name":"distacia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Distancia (m)")]),_c('input-miles',{staticStyle:{"width":"130px"},attrs:{"money-options":_vm.config_distancias},model:{value:(_vm.model.distancia),callback:function ($$v) {_vm.$set(_vm.model, "distancia", $$v)},expression:"model.distancia"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-auto"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"rules":"required|max:5","name":"valor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Valor")]),_c('input-miles',{staticStyle:{"width":"130px"},model:{value:(_vm.model.valor),callback:function ($$v) {_vm.$set(_vm.model, "valor", $$v)},expression:"model.valor"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Crear rango","placement":"bottom"}},[_c('div',{staticClass:"bg-general mt-3 cr-pointer d-middle-center br-10",staticStyle:{"width":"44px","height":"44px"},on:{"click":function($event){return _vm.insertDistancia(valid)}}},[_c('i',{staticClass:"icon-plus f-20 text-white"})])])],1)]}}])}),_c('p',{staticClass:"mx-3 my-2"},[_vm._v("Rangos de distancias")]),_c('ValidationObserver',{ref:"validacion_domicilio",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return _vm._l((_vm.distancias),function(distancia,idx){return _c('div',{key:idx,staticClass:"row mx-0 my-2"},[_c('div',{staticClass:"col-auto"},[_c('input-miles',{staticStyle:{"width":"130px"},attrs:{"money-options":idx == _vm.distancias.length - 1 ? _vm.config_distancia_final : _vm.config_distancias,"disabled":""},model:{value:(distancia.distancia_inicial),callback:function ($$v) {_vm.$set(distancia, "distancia_inicial", $$v)},expression:"distancia.distancia_inicial"}})],1),(idx !== _vm.distancias.length - 1)?_c('span',{staticClass:"mt-2"},[_vm._v("A")]):_vm._e(),(idx !== _vm.distancias.length - 1)?_c('div',{staticClass:"col-auto"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"rules":"required|max:5","name":"hasta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-miles',{staticStyle:{"width":"130px"},attrs:{"disabled":_vm.editar!=idx,"money-options":_vm.config_distancias},model:{value:(distancia.distancia),callback:function ($$v) {_vm.$set(distancia, "distancia", $$v)},expression:"distancia.distancia"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e(),(idx == _vm.distancias.length - 1)?_c('div',{staticStyle:{"width":"170px"}}):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"rules":"required|max:5","name":"valor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-miles',{staticStyle:{"width":"130px"},attrs:{"disabled":_vm.editar!=idx},model:{value:(distancia.valor),callback:function ($$v) {_vm.$set(distancia, "valor", $$v)},expression:"distancia.valor"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-auto px-2"},[(_vm.editar!=idx)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Editar","placement":"bottom"}},[_c('div',{staticClass:"d-middle-center btn-edit cr-pointer br-10",staticStyle:{"width":"44px","height":"44px"}},[_c('i',{staticClass:"icon-pencil-outline f-18",on:{"click":function($event){_vm.editar=idx}}})])]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Guardar","placement":"bottom"}},[_c('div',{staticClass:"bg-general2 cr-pointer d-middle-center br-10",staticStyle:{"width":"44px","height":"44px"},on:{"click":function($event){return _vm.updateDistancia(distancia, valid)}}},[_c('i',{staticClass:"icon-ok-circled-outline text-white f-20"})])])],1),_c('div',{staticClass:"col-auto px-0"},[(_vm.editar!=idx && distancia.id !== 0)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Eliminar","placement":"bottom"}},[_c('div',{staticClass:"d-middle-center cr-pointer btn-delete br-10",staticStyle:{"width":"44px","height":"44px"},on:{"click":function($event){return _vm.eliminar(distancia.id)}}},[_c('i',{staticClass:"icon-trash-empty f-20 "})])]):_vm._e()],1)])})}}])}),(!_vm.distancias.length)?_c('sinDatos',{attrs:{"mensaje":"No se ha creado ningún rango de distancia"}}):_vm._e()],1),_c('modalEliminar',{ref:"modalEliminar",attrs:{"titulo":"Eliminar rango del domicilio","mensaje":"¿Desea eliminar el rango del domicilio?"},on:{"eliminar":_vm.deleteDistancia}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }