import axios from "axios"

const API = "datos-tienda"

const datosTienda = {
    getDatosTienda(id_tienda){
        return axios(`${API}/${id_tienda}/get-datos-tienda`)
    },
    paisPostulacion(id_tienda){
        return axios(`${API}/${id_tienda}/pais-postulacion`)
    },
    getDatos(id_tienda, params){
        return axios(`${API}/${id_tienda}/get-datos`, { params })
    },
    getTipoTienda(){
        return axios(`${API}/get-tipo-tienda`);
    },
    updateDatos(params){
        return axios.post(`${API}/update-datos`, params)
    },
    getPaises(proyecto){
        return axios.get(`proyecto/${proyecto}/pais`)
    },
    getEstados(proyecto,pais){
        return axios.get(`proyecto/${proyecto}/pais/${pais}/estados`)
    },
    getCiudades(proyecto,estado){
        return axios.get(`proyecto/${proyecto}/estado/${estado}/ciudades`)
    },
}

export default datosTienda
