import axios from "axios"

const API = "domicilios"

const datosTienda = {
    getDatos(id_tienda,tipo_tienda){
        return axios(`${API}/${id_tienda}/${tipo_tienda}/get-datos`)
    },
    updateDatos(id,params){
        return axios.put(`${API}/${id}/update-datos`, params);
    },
    insertDistancia(params){
        return axios.post(`${API}/insert-distancia`, params)
    },
    updateDistancia(params){
        return axios.post(`${API}/update-distancia`, params)
    },
    deleteDistancia(id){
        return axios.delete(`${API}/${id}/delete-distancia`);
    },
}

export default datosTienda
