<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
            <p>Unidades de medida</p>
        </div>
        <div class="p-3 f-15 custom-scroll alto-content">
            <div class="row mx-0 my-3 px-3">
                Crea las unidades de medida para los productos que vendas
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:12" name="unidad de medida" class="d-flex flex-column">
                            <label class="ml-2">Unidad de medida</label>
                            <el-input v-model="model.nombre" maxlength="12" show-word-limit>
                                <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="valor" class="d-flex flex-column">
                            <label class="ml-2">Siglas</label>
                            <el-input v-model="model.sigla" style="width: 110px;" maxlength="5" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <el-tooltip class="item" effect="light" content="Crear rango" placement="bottom">
                        <div class="bg-general mt-3 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="insertUnidad(valid)">
                            <i class="icon-plus f-20 text-white" />
                        </div>
                    </el-tooltip>
                </div>
            </ValidationObserver>
            <p class="mx-3 my-2">Lista de unidades</p>
            <ValidationObserver ref="validacion2" v-slot="{ valid }">
                <div v-for="(unidad, idx) in unidades" :key="idx" class="row mx-0 my-2">
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required" name="desde" class="d-flex flex-column">
                            <el-input v-model="unidad.nombre" :disabled="editar!=idx" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required" name="hasta" class="d-flex flex-column">
                            <el-input v-model="unidad.sigla" :disabled="editar!=idx" style="width: 80px;" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto px-2">
                        <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                            <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;">
                                <i class="icon-pencil-outline f-18" @click="editar=idx" />
                            </div>
                        </el-tooltip>
                        <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                            <div class="bg-general2 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="updateUnidad(unidad, valid)">
                                <i class="icon-ok-circled-outline text-white f-20" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-auto px-0">
                        <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                            <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Eliminar" placement="bottom">
                                <div class="d-middle-center br-10" style="width:44px;height:44px;" @click="eliminar(unidad.id)">
                                    <i class="icon-trash-empty f-20" />
                                </div>
                            </el-tooltip>
                        </el-tooltip>
                    </div>
                    <el-tooltip class="item" effect="light" placement="bottom">
                        <div slot="content" class="text-center" style="max-width:135px;">
                            Productos que utilizan esta unidad de medida
                        </div>
                        <div class="br-10 text-white px-2 f-17 m-2 mb-auto" :class="true?'bg-gr-red': 'bg-dark'">
                            <i class="icon-package-variant-closed f-18 mr-2" />
                            <span>{{ unidad.productos_count }}</span>
                        </div>
                    </el-tooltip>
                </div>
            </ValidationObserver>
            <sinDatos v-if="!unidades.length" mensaje="No se ha creado ninguna unidad de medida" />
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar unidad de medida" mensaje="¿Desea eliminar la unidad de medida? Esta acción no puede ser revertida." @eliminar="deleteUnidad" />
    </section>
</template>

<script>
import Unidades from "../../../services/configurar/productos";

export default {
    data(){
        return {
            model: {
                nombre: '',
                sigla: ''
            },
            editar: -1,
            unidades: [],
            id_unidad: null,
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await Unidades.getDatosUnidades(this.$usuario.tienda.id,2)
                this.unidades = data.unidades
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateUnidad(unidad,valid){
            try {
                if(valid){
                    let datos = {
                        id: unidad.id,
                        nombre: unidad.nombre,
                        sigla: unidad.sigla,
                        id_tienda: this.$usuario.tienda.id,
                    }
                    const {data} = await Unidades.updateUnidad(datos)
                    this.editar=-1
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async insertUnidad(valid){
            try {
                if(valid){
                    let datos = {
                        nombre: this.model.nombre,
                        sigla: this.model.sigla,
                        id_tienda: this.$usuario.tienda.id,
                    }
                    const {data} = await Unidades.insertUnidad(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                    this.model.nombre = ''
                    this.model.sigla = ''
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async deleteUnidad(){
            try {
                const {data} = await Unidades.deleteUnidad(this.id_unidad)
                this.notificacion('', data.message, 'success')
                this.getDatos()
                this.id_unidad = null
                this.$refs.modalEliminar.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            this.id_unidad = id
            this.$refs.modalEliminar.toggle()
        }
    }
}
</script>
