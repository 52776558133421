<template>
    <section>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
                <p class="text-general f-600 f-18">Pedidos</p>
            </div>
            <div class="p-3 f-15 custom-scroll alto-content">
                <div class="row mx-0 my-3">
                    <div class="col-xl-6 col-lg-6 col-md col-sm col">
                        <label class="pl-3 text-general f-15">Valor mínimo de pedido</label>
                        <ValidationProvider v-slot="{errors}" rules="required|numeric|max:5" name="pedido">
                            <input-miles v-model="model.pedido" @blur.native="CrearEditar" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-xl-5 col-lg-5 col-md col-sm col">
                        <el-switch v-model="model.chat_visible" inactive-text="Chat visible en el pedido" active-color="#000000" @change="activarInactiva" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import PedidosMinimo from '~/services/configurar/tendero/pedidos'
export default {
    data(){
        return {
            model:{
                pedido:0,
                chat_visible:null
            },
            despacho: '',
        }
    },
    mounted(){
        this.listarPedidoMinimo()
    },
    methods:{
        async listarPedidoMinimo(){
            try {
                const {data} = await PedidosMinimo.GetTPedidosMinimo(this.$usuario.tienda.id)
                this.model.pedido =  data.pedido_minimo
                this.model.chat_visible =  data.visible =  data.chat_clientes ? true : false
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearEditar(){
            try {
                let datos ={
                    id:this.$usuario.tienda.id,
                    pedido_minimo:this.model.pedido,
                }
                const {data} = await PedidosMinimo.PostCrearEditar(datos)
                this.notificacion('', data.mensaje, 'success')
                this.listarPedidoMinimo()
            } catch (e){
                this.error_catch(e)
            }
        },
        async activarInactiva(event){
            try {
                let datos ={
                    id:this.$usuario.tienda.id,
                    chat_clientes:event
                }
                const {data} = await PedidosMinimo.activarInactiva(datos)
                this.notificacion('', data.mensaje, 'success')
                this.listarPedidoMinimo()
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
