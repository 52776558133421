<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
            <p>Domicilios</p>
            <div class="bg-gr-red cr-pointer br-10 px-3 py-1 text-white" @click="updateDatos">
                Guardar
            </div>
        </div>
        <div class="p-3 f-15 custom-scroll alto-content">
            <ValidationObserver ref="validacion_cobertura">
                <div class="row mx-0 my-3">
                    <div class="col-4">
                        <label>Cobertura de tu tienda (metros)</label>
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="cobertura">
                            <input-miles v-model="datos.cobertura" :money-options="config_distancias" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <label>Indique el valor mínimo del pedido para que el domicilio sea gratis</label>
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="valor mínimo">
                            <input-miles v-model="datos.domicilio_gratis" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            <p class="text-general f-12">En su tienda se visualizará con domicilios gratis</p>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
            <ValidationObserver ref="validacion_domicilio" v-slot="{ valid }">
                <p class="m-3">Crea los rangos del Domicio y su valor</p>
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="distacia" class="d-flex flex-column">
                            <label>Distancia (m)</label>
                            <input-miles v-model="model.distancia" :money-options="config_distancias" style="width: 130px;" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="valor" class="d-flex flex-column">
                            <label>Valor</label>
                            <input-miles v-model="model.valor" style="width: 130px;" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <el-tooltip class="item" effect="light" content="Crear rango" placement="bottom">
                        <div class="bg-general mt-3 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="insertDistancia(valid)">
                            <i class="icon-plus f-20 text-white" />
                        </div>
                    </el-tooltip>
                </div>
            </ValidationObserver>
            <p class="mx-3 my-2">Rangos de distancias</p>
            <ValidationObserver ref="validacion_domicilio" v-slot="{ valid }">
                <div v-for="(distancia, idx) in distancias" :key="idx" class="row mx-0 my-2">
                    <div class="col-auto">
                        <input-miles v-model="distancia.distancia_inicial" :money-options="idx == distancias.length - 1 ? config_distancia_final : config_distancias" disabled style="width: 130px;" />
                    </div>
                    <span v-if="idx !== distancias.length - 1" class="mt-2">A</span>
                    <div v-if="idx !== distancias.length - 1" class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="hasta" class="d-flex flex-column">
                            <input-miles v-model="distancia.distancia" :disabled="editar!=idx" :money-options="config_distancias" style="width: 130px;" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div v-if="idx == distancias.length - 1" style="width: 170px;" />
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" rules="required|max:5" name="valor" class="d-flex flex-column">
                            <input-miles v-model="distancia.valor" :disabled="editar!=idx" style="width: 130px;" />
                            <!-- <el-input v-model="valor2" :disabled="editar!=idx" style="width: 130px;" /> -->
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto px-2">
                        <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                            <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;">
                                <i class="icon-pencil-outline f-18" @click="editar=idx" />
                            </div>
                        </el-tooltip>
                        <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                            <div class="bg-general2 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="updateDistancia(distancia, valid)">
                                <i class="icon-ok-circled-outline text-white f-20" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div class="col-auto px-0">
                        <el-tooltip v-if="editar!=idx && distancia.id !== 0" class="item" effect="light" content="Eliminar" placement="bottom">
                            <div class="d-middle-center cr-pointer btn-delete br-10" style="width:44px;height:44px;" @click="eliminar(distancia.id)">
                                <i class="icon-trash-empty f-20 " />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <sinDatos v-if="!distancias.length" mensaje="No se ha creado ningún rango de distancia" />
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar rango del domicilio" mensaje="¿Desea eliminar el rango del domicilio?" @eliminar="deleteDistancia" />
    </section>
</template>

<script>
import Domicilios from "../../../services/configurar/tendero-vip/domicilios";

export default {
    data(){
        return {
            editar: -1,
            config_distancias: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' m',
                precision: 0,
                masked: false
            },
            config_distancia_final: {
                decimal: ',',
                thousands: '.',
                prefix: '+ ',
                suffix: ' m',
                precision: 0,
                masked: false
            },
            datos:{
                cobertura: 0,
                domicilio_gratis: 0,
            },
            model:{
                distancia: 0,
                valor: 0,
            },
            id_distancia: null,
            distancias: []
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await Domicilios.getDatos(this.$usuario.tienda.id,2)
                this.distancias = data.distancias
                this.datos = data.datos
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateDistancia(distancia,valid){
            try {
                if(valid){
                    let datos = {
                        id_distancia: distancia.id_distancia,
                        id: distancia.id,
                        valor: distancia.valor,
                        distancia: distancia.distancia,
                        id_tienda: this.$usuario.tienda.id
                    }
                    const {data} = await Domicilios.updateDistancia(datos)
                    this.editar=-1
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async insertDistancia(valid){
            try {
                if(valid){
                    let datos = {
                        valor: this.model.valor,
                        distancia: this.model.distancia,
                        id_tienda: this.$usuario.tienda.id
                    }
                    const {data} = await Domicilios.insertDistancia(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                    this.model.distancia = 0
                    this.model.valor = 0
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateDatos(){
            try {
                const valid = await this.$refs.validacion_cobertura.validate()
                if(valid){
                    let datos = {
                        cobertura: this.datos.cobertura,
                        domicilio_gratis: this.datos.domicilio_gratis,
                    }
                    const {data} = await Domicilios.updateDatos(this.$usuario.tienda.id, datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async deleteDistancia(){
            try {
                const {data} = await Domicilios.deleteDistancia(this.id_distancia)
                this.notificacion('', data.message, 'success')
                this.getDatos()
                this.id_distancia = null
                this.$refs.modalEliminar.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            this.id_distancia = id
            this.$refs.modalEliminar.toggle()
        }
    }
}
</script>
