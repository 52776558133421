<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
            <p>Categorías de productos</p>
        </div>
        <div class="p-3 f-15 custom-scroll alto-content">
            <div class="row mx-0 my-3 px-3 align-items-center">
                <p>Crea las categorías que agruparan a los productos que vendes</p>
                <el-tooltip class="item" effect="light" content="Crear categoria" placement="bottom">
                    <div class="bg-general ml-3 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="crearCategoria(null)">
                        <i class="icon-plus f-20 text-white" />
                    </div>
                </el-tooltip>
            </div>
            <p class="m-3">Lista de categorías</p>
            <div v-for="(cat, idx) in categorias" :key="idx" class="row mx-0 my-3 px-3">
                <div class="col-auto px-0 position-relative text-right" :class="cat.banner_firmado ? '' : 'bg-gris0'" style="width:400px">
                    <p class="f-600 f-17 name-banner">{{ cat.nombre }}</p>
                    <img v-if="cat.banner_firmado" class="obj-cover" width="400" height="50" :src="cat.banner_firmado" alt="" />
                    <img v-else src="/img/no-imagen/list.svg" height="30" class="m-2" />
                </div>
                <div class="col-auto px-2">
                    <el-tooltip class="item" effect="light" content="Editar" placement="bottom">
                        <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;" @click="crearCategoria(cat)">
                            <i class="icon-pencil-outline f-18" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto px-0">
                    <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                        <div class="d-middle-center cr-pointer btn-delete br-10" style="width:44px;height:44px;" @click="eliminar(cat.id)">
                            <i class="icon-trash-empty f-20 " />
                        </div>
                    </el-tooltip>
                </div>
                <el-tooltip class="item" effect="light" placement="bottom">
                    <div slot="content" class="text-center" style="max-width:135px;">
                        Productos ubicados en esta categoria
                    </div>
                    <div class="br-10 text-white px-2 f-17 m-2 mb-auto" :class="true?'bg-gr-red': 'bg-dark'">
                        <i class="icon-package-variant-closed f-18 mr-2" />
                        <span>{{ cat.productos }}</span>
                    </div>
                </el-tooltip>
            </div>
            <sinDatos v-if="!categorias.length" mensaje="No se ha creado ninguna categoría" />
        </div>
        <modalCrearCategoria ref="modalCrearCategoria" @actualizar="getDatos" />
        <modalEliminar ref="modalEliminar" titulo="Eliminar unidad de medida" mensaje="¿Desea eliminar la unidad de medida? Esta acción no puede ser revertida." @eliminar="deleteCategoria" />
    </section>
</template>

<script>
import Categorias from "../../../../services/configurar/productos";

export default {
    components: {
        modalCrearCategoria: () => import('./modalCrearCategoria')
    },
    data(){
        return {
            categorias: [],
            id_categoria: null
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await Categorias.getDatosCategorias(this.$usuario.tienda.id,2)
                this.categorias = data.categorias
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            this.id_categoria = id

            this.$refs.modalEliminar.toggle()
        },
        async deleteCategoria(){
            try {
                const {data} = await Categorias.deleteCategoria(this.id_categoria)
                this.notificacion('', data.message, 'success')
                this.getDatos()
                this.id_categoria = null
                this.$refs.modalEliminar.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        crearCategoria(cat){
            this.$refs.modalCrearCategoria.toggle(cat)
        }
    }
}
</script>
<style lang="scss" scoped>
.name-banner{
    position: absolute;
    color: #ffffff;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
}
.bg-gris0{
    background: #d0d0d0;
}
</style>
