<template>
    <section class="h-100">
        <div class="row mx-0 px-3 pl-5 f-600 border-bottom py-2 justify-content-between align-items-center">
            <p>Etiquetas</p>
        </div>
        <div class="p-3 f-15 custom-scroll alto-content">
            <div class="row mx-0 my-3 px-3">
                Crea las etiquetas que identificará los productos que vendes
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-3">
                    <div class="col-auto">
                        <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:15" name="etiqueta" class="d-flex flex-column">
                            <label class="ml-2">Etiqueta</label>
                            <el-input v-model="model.nombre" maxlength="15" show-word-limit>
                                <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <el-tooltip class="item" effect="light" content="Crear etiqueta" placement="bottom">
                        <div class="bg-general mt-3 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="insertEtiqueta(valid)">
                            <i class="icon-plus f-20 text-white" />
                        </div>
                    </el-tooltip>
                </div>
            </ValidationObserver>
            <p class="mx-3 my-2">Lista de etiquetas</p>
            <ValidationObserver ref="validacion_domicilio" v-slot="{ valid }">
                <div class="row mx-0">
                    <div v-for="(etiqueta, idx) in etiquetas" :key="idx" class="col-6 px-1">
                        <div class="row mx-0 my-2">
                            <div class="col-auto px-2">
                                <ValidationProvider v-slot="{errors}" rules="required|max:15" name="etiqueta" class="d-flex flex-column">
                                    <el-input v-model="etiqueta.nombre" :disabled="editar!=idx" style="width:150px;" maxlength="15" show-word-limit />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto px-0">
                                <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                                    <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;">
                                        <i class="icon-pencil-outline f-18" @click="editar=idx" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                    <div class="bg-general2 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="updateEtiqueta(etiqueta, valid)">
                                        <i class="icon-ok-circled-outline text-white f-20" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="col-auto px-0">
                                <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Eliminar" placement="bottom">
                                    <div class="d-middle-center br-10" style="width:44px;height:44px;" @click="eliminar(etiqueta.id)">
                                        <i class="icon-trash-empty f-20" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <el-tooltip class="item" effect="light" placement="bottom">
                                <div slot="content" class="text-center" style="max-width:135px;">
                                    Productos que utilizan esta etiqueta
                                </div>
                                <div class="br-10 text-white px-2 f-17 m-2 mb-auto" :class="true?'bg-gr-red': 'bg-dark'">
                                    <i class="icon-package-variant-closed f-18 mr-2" />
                                    <span>{{ etiqueta.productos_count }}</span>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
            <sinDatos v-if="!etiquetas.length" mensaje="No se ha creado ninguna etiqueta" />
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar etiqueta" mensaje="¿Desea eliminar la etiqueta? Esta acción no puede ser revertida." @eliminar="deleteEtiqueta" />
    </section>
</template>

<script>
import Etiquetas from "../../../services/configurar/productos";

export default {
    data(){
        return {
            model: {
                nombre: '',
            },
            editar: -1,
            etiquetas: [],
            id_etiqueta: null
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await Etiquetas.getDatosEtiquetas(this.$usuario.tienda.id,2)
                this.etiquetas = data.etiquetas
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateEtiqueta(etiqueta,valid){
            try {
                if(valid){
                    let datos = {
                        id: etiqueta.id,
                        nombre: etiqueta.nombre,
                        id_tienda: this.$usuario.tienda.id,
                    }
                    const {data} = await Etiquetas.updateEtiqueta(datos)
                    this.editar=-1
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async insertEtiqueta(valid){
            try {
                if(valid){
                    let datos = {
                        nombre: this.model.nombre,
                        id_tienda: this.$usuario.tienda.id,
                    }
                    const {data} = await Etiquetas.insertEtiqueta(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.getDatos()
                    this.model.nombre = ''
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async deleteEtiqueta(){
            try {
                const {data} = await Etiquetas.deleteEtiqueta(this.id_etiqueta)
                this.notificacion('', data.message, 'success')
                this.getDatos()
                this.id_etiqueta = null
                this.$refs.modalEliminar.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            this.id_etiqueta = id
            this.$refs.modalEliminar.toggle()
        }
    }
}
</script>
