<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 justify-content-between align-items-center">
            <p>Datos de la tienda</p>
            <div class="bg-gr-red cr-pointer br-10 px-3 py-1 text-white" @click="editarDatos">
                Editar datos
            </div>
        </div>
        <div class="p-3 f-15 custom-scroll alto-content">
            <div class="row mx-0">
                <img class="obj-cover border br-5 mx-2" width="160" height="160" :src="tienda.logo_mini_firmado" alt="" />
                <div class="col">
                    <p class="f-22 f-600 text-general my-2">{{ tienda.nombre }}</p>
                    <p class="my-2"><strong>Dueño: </strong>{{ tienda.propietario_nombre }}</p>
                    <p class="my-2"><strong>{{ tienda.ciudad }} </strong>{{ tienda.departamento }}</p>
                    <p class="my-2"><strong>Número de celular: </strong>{{ tienda.telefono_celular }}</p>
                    <p class="my-2"><strong>Dirección: </strong>{{ tienda.direccion }}</p>
                    <p class="my-2"><strong>Complemento: </strong> {{ _.defaultTo(tienda.direccion_anexo,'No registra') }} </p>
                    <div class="row mx-0">
                        <img :src="rutaImagenMapa()" class="w-100 cr-pointer" @click="abrirModalMapa" />
                    </div>
                    <p class="my-2"><strong>Barrio: </strong>{{ _.defaultTo(tienda.barrio,'No registra') }}</p>
                    <p class="my-2"><strong>Descripción: </strong>{{ tienda.descripcion }} </p>
                    <p class="my-2"><strong>NIT. de la tienda: </strong>{{ _.defaultTo(tienda.nit,'No registra') }}</p>
                    <div v-if="tienda.nit_soporte" class="row mx-0">
                        <div id="df_gbp" class="bg-gr-general br-12 d-middle-center text-white px-5 " :class="file_type(tienda.nit_soporte) === 'application/pdf' ? '_df_custom' : ''" style="height:40px;width:380px;" @click="openAdjunto(tienda)">
                            <i class="icon-doc-inv" />
                            Adjunto: Soporte Nit
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalEditarDatos ref="modalEditarDatos" @actualizar="getDatos" />
        <!-- <mapa-modal-google ref="modalVerMapa" :coordenadas.sync="coordenadas" /> -->
        <mapa-google-modal
        ref="modalMapaGoogle"
        :desactivar="true"
        :coordenadas-entrada="coordenadas"
        />
        <modal-imagen ref="modal_imagen" />
    </section>
</template>

<script>
import DatosTienda from "../../../../services/configurar/tendero-vip/datos_tienda";
export default {
    components: {
        modalEditarDatos: () => import('./modalEditarDatos'),
    },
    data(){
        return {
            model: { longitud: '', latitud: '' },
            tienda: {
                barrio:null,
                descripcion:null,
                direccion:null,
                direccion_anexo:null,
                estado:null,
                id:null,
                idm_ciudad:null,
                idm_estado:null,
                idm_pais:null,
                latitud:null,
                logo:null,
                logo_mini:null,
                logo_mini_firmado:null,
                longitud:null,
                nit:null,
                nit_soporte:null,
                nit_soporte_firmado:null,
                nombre:null,
                // propietario_identificacion:null,
                propietario_nombre:null,
                telefono_celular:null,
            },
            coordenadas: { lat: 0, lng: 0 }
        }
    },
    computed: {
        estado_pago(){
            return this.tienda.paz_y_salvo.dif <= 0 ? 'Vigente' : 'En mora'
        },
        color_estado_pago(){
            return this.tienda.paz_y_salvo.dif <= 0 ? 'bg-gr-general' : 'bg-gr-red'
        }
    },
    mounted(){
        this.getDatos()
    },
    methods: {
        async getDatos(){
            try {
                const { data } = await DatosTienda.getDatos(this.$usuario.tienda.id)
                this.tienda = data.data
                this.coordenadas.lat = +this.tienda.latitud
                this.coordenadas.lng = +this.tienda.longitud
            } catch (e){
                this.error_catch(e)
            }
        },
        editarDatos(){
            this.$refs.modalEditarDatos.toggle(this.tienda)
        },
        verMapa(){
            console.log('primero');
            // this.$refs.modalVerMapa.toggle()
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        openAdjunto(item){
            if(this.file_type(item.nit_soporte) == 'application/pdf'){
                this.set_pdf(item.nit_soporte_firmado)
            }else if(this.file_type(item.nit_soporte) == 'image/*'){
                this.$refs.modal_imagen.toggle(item.nit_soporte_firmado)
            }
        },
    }
}
</script>

<style lang="css" scoped>
.text-orange{
    color: #FF612B;
}
</style>
