<template>
    <section>
        <div class="row mx-0">
            <menuPrincipal :menus="menus" />
            <div class="px-0 col border br-10 ml-3 bg-white w-content">
                <!-- <component :is="activo" /> -->
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    metaInfo:()=>({ title: 'Configurar Tendero' }),
    data(){
        return {
            activo: 'datosTienda',
            menus: [
                { nombre: 'Datos de la tienda', ruta: 'tendero-vip.conf.datosTienda', icon: 'icon-storefront-outline' },
                { nombre: 'Horario de atención', ruta: 'tendero-vip.conf.horario-atencion', icon: 'icon-clock' },
                { nombre: 'Domicilios', ruta: 'tendero-vip.conf.domicilios', icon: 'icon-bike' },
                { nombre: 'Pedidos', ruta: 'tendero-vip.conf.pedidos', icon: 'icon-receipt' },
                { nombre: 'Unidades de medida', ruta: 'tendero-vip.conf.unidades-medida', icon: 'icon-ruler' },
                { nombre: 'Categorías de productos', ruta: 'tendero-vip.conf.categorias', icon: 'icon-format-list-bulleted-type' },
                { nombre: 'Etiquetas de productos', ruta: 'tendero-vip.conf.etiquetas', icon: 'icon-tag' },
                { nombre: 'Tiempos de estados de los pedidos', ruta: 'tendero-vip.conf.tiempos', icon: 'icon-clock-end' },
                { nombre: 'Criterios de calificación de pedidos', ruta: 'tendero-vip.conf.criterios', icon: 'icon-star' },
                { nombre: 'Motivos de rechazo por tendero', ruta: 'tendero-vip.conf.motRechazo', icon: 'icon-playlist-remove' },
                { nombre: 'Motivos de cancelación por tendero', ruta: 'tendero-vip.conf.motCanTendero', icon: 'icon-cancel-circled-outline' },
                { nombre: 'Motivo cancelacion por cliente', ruta: 'tendero-vip.conf.motCanCliente', icon: 'icon-account-remove-outline' },
                { nombre: 'Motivos de reclamo', ruta: 'tendero-vip.conf.motReclamo', icon: 'icon-attention-alt' },
                { nombre: 'Datos de acceso', ruta: 'tendero-vip.conf.datos-acceso', icon: 'icon-lock' },
            ]
        }
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    height: calc(100vh - 65px);
    background: #fff;
}
</style>
